<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register fiveitems">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="item.active ? 'active' : item.finish ? 'done' : ''"
                  @click="
                    item.finish && stepdetail < 5 && changeStep(item.linkItems)
                  "
                >
                  <a href="#">
                    <span class="icons icon-checkmark"></span>
                    <span class="number">{{ item.step }}</span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box w-630">
              <h3 class="title-md mb-2">{{ $t("DetailStep4.title") }}</h3>

              <div class="form-group">
                <label class="light">{{ $t("DetailStep4.label") }}</label>
              </div>

              <div class="form-report">
                <div class="form-hgroup">
                  <h3>{{ $t("DetailStep4.title_property_detail") }}</h3>
                  <a class="edit" @click="goupdatedata(1)">
                    {{
                    $t("DetailStep4.link_edit_property_detail")
                    }}
                  </a>
                </div>

                <table class="table-report">
                  <tr>
                    <th>{{ $t("DetailStep4.title_fullname_acc") }}</th>
                    <td>{{ propertyDetail.acc_name }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_address") }}</th>
                    <td>{{ propertyDetail.address }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_unitnum") }}</th>
                    <td>{{ propertyDetail.unitnumber }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_postcode") }}</th>
                    <td>{{ propertyDetail.postcode }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_city") }}</th>
                    <td>{{ propertyDetail.district }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_country") }}</th>
                    <td>{{ propertyDetail.country }}</td>
                  </tr>
                </table>
              </div>
              <div class="form-report">
                <div class="form-hgroup">
                  <h3>{{ $t("DetailStep4.title_owner_detail") }}</h3>
                  <a class="edit" @click="goupdatedata(2)">
                    {{
                    $t("DetailStep4.link_edit_owner_detail")
                    }}
                  </a>
                </div>

                <table class="table-report">
                  <tr>
                    <th>{{ $t("DetailStep4.title_acc") }}</th>
                    <td>{{ ownerDetail.accommodation_owned_by }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_fullname") }}</th>
                    <td>
                      <template v-for="(item, index) in ownerDetail.owner_name">
                        <div :key="index">{{ item.first_name }} {{ item.last_name }}</div>
                      </template>
                    </td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_alternative") }}</th>
                    <td>{{ ownerDetail.alternative_name }}</td>
                  </tr>
                </table>
              </div>

              <div class="form-report">
                <div class="form-hgroup">
                  <h3>{{ $t("DetailStep4.title_manager_detail") }}</h3>
                  <a class="edit" @click="goupdatedata(3)">
                    {{
                    $t("DetailStep4.link_edit_manager_detail")
                    }}
                  </a>
                </div>

                <table class="table-report">
                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_acc") }}</th>
                    <td>{{ managerDetail.accommodation_managed_by }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_fullname") }}</th>
                    <td>{{ managerDetail.management_company_name }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_alternative") }}</th>
                    <td>{{ managerDetail.alternative_name }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_unitnum") }}</th>
                    <td>{{ managerDetail.unit_number }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_phone") }}</th>
                    <td>{{ managerDetail.phone }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_telephone") }}</th>
                    <td>{{ managerDetail.telephone }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_email") }}</th>
                    <td>{{ managerDetail.email }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_city") }}</th>
                    <td>{{ managerDetail.city }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_postcode") }}</th>
                    <td>{{ managerDetail.postcode }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("DetailStep4.title_manager_country") }}</th>
                    <td>{{ managerDetail.country }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <button
              type="button"
              class="btn gl-bt hover_btn"
              style="text-transform: capitalize !important"
              :disabled="!cangonextstep"
              @click="confirmData()"
            >
              <span>{{ $t("DetailStep3.btn_next") }}</span>
            </button>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a :href="pathrightphoto" class="img-exemple iphone-model" data-fancybox>
              <img
                :src="pathrightphoto"
                style="
                  border-radius: 0.938rem !important;
                  object-fit: cover !important;
                "
                alt
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";

export default {
  data: () => ({
    stepdetail: 4,
    propertyDetail: {
      acc_name: "",
      address: "",
      unitnumber: "",
      postcode: "",
      district: "",
      amphoe: "",
      province: "",
      country: ""
    },
    ownerDetail: {
      accommodation_owned_by: "",
      owner_name: [],
      alternative_name: ""
    },
    managerDetail: {
      accommodation_managed_by: "",
      management_company_name: "",
      alternative_name: "",
      address: "",
      unit_number: "",
      phone: "",
      telephone: "",
      email: "",
      city: "",
      amphoe: "",
      province: "",
      country: "",
      postcode: ""
    },
    cangonextstep: false,
    itemsStep: [],
    propertyData: {},
    pathrightphoto: "",
    propertyID: "",
    userToken: ""
  }),
  created() {
    const self = this;
    self.propertyID = self.$route.params.propertyId;
    self.userToken = self.$route.params.userToken;
    self.initAll();
  },
  mounted() {
    EventBus.$on("sendPropertyDetailData", this.getConfirmationData);
    EventBus.$on("refreshRightPhoto", this.initAll);
    this.colorHover();
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    initAll() {
      const self = this;
      self.initCountry();
    },
    async initCountry() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var tempCountry = [];
      var temp = [];
      self.loadingCountry = true;
      self.loadingProvince = true;
      self.itemsCountry = [];
      self.itemsProvince = [];
      self.itemsAmphoe = [];
      self.itemsDistrict = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/setting/country`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map(el => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || []
              });
              if (el?.province?.length) {
                el?.province?.map(ele => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || ""
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.loadingCountry = false;
        self.loadingProvince = false;
        self.itemsCountry = tempCountry;
        self.itemsProvince = temp;
        self.itemsAmphoe = temp;
        self.itemsDistrict = temp;
        self.initStepData();
      }
    },
    async initStepData() {
      const self = this;
      self.itemsStep = [];
      var temp = {};
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.result[0])
          res?.data?.result?.map(el => {
            if (el._id == self.propertyID) temp = el;
          });
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.stepdetail1"),
                active: (self.stepdetail == 1 && true) || false,
                finish: res?.data?.result[0]?.property_detail?.success || false,
                linkItems: {
                  link: `signup/propertydetail/${self.propertyID}`,
                  name: "SignupPropertyDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 1
              },
              {
                name: self.$t("SignupStep.stepdetail2"),
                active: (self.stepdetail == 2 && true) || false,
                finish: res?.data?.result[0]?.owner_detail?.success || false,
                linkItems: {
                  link: `signup/ownerdetail/${self.propertyID}`,
                  name: "SignupOwnerDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 2
              },
              {
                name: self.$t("SignupStep.stepdetail3"),
                active: (self.stepdetail == 3 && true) || false,
                finish: res?.data?.result[0]?.manager_detail?.success || false,
                linkItems: {
                  link: `signup/managerdetail/${self.propertyID}`,
                  name: "SignupManagerDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 3
              },
              {
                name: self.$t("SignupStep.stepdetail4"),
                active: (self.stepdetail == 4 && true) || false,
                finish: self.stepdetail == 5,
                linkItems: {
                  link: `signup/confirmation/${self.propertyID}`,
                  name: "SignupConfirmationScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 4
              }
            ];
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.getStepData(self.itemsStep);
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
      self.initConfirmationData();
    },
    changePhotoRight() {
      const self = this;
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/aigent_signup_bg.jpg`;
    },
    async initConfirmationData() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          temp = res.data.result;
          self.propertyData = res.data.result[0];
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.getConfirmationData(temp);
      }
    },
    getConfirmationData(data) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const tempPropertyCountry = self?.itemsCountry?.find(
        p => p.name == data[0]?.property_detail?.country
      );
      const tempPropertyProvince = self?.itemsDistrict?.find(
        p => p.province == data[0]?.property_detail?.province
      );
      const tempPropertyAmphoe = self?.itemsDistrict?.find(
        p => p.amphoe == data[0]?.property_detail?.district
      );
      const tempPropertyCity = self?.itemsDistrict?.find(
        p => p.district == data[0]?.property_detail?.city
      );

      const tempManagerCountry = self?.itemsCountry?.find(
        p => p.name == data[0]?.manager_detail?.country
      );
      const tempManagerProvince = self?.itemsDistrict?.find(
        p => p.province == data[0]?.manager_detail?.province
      );
      const tempManagerAmphoe = self?.itemsDistrict?.find(
        p => p.amphoe == data[0]?.manager_detail?.district
      );
      const tempManagerCity = self?.itemsDistrict?.find(
        p => p.district == data[0]?.manager_detail?.city
      );
      if (data[0]?.property_detail?.success) {
        self.propertyDetail = {
          acc_name: data[0]?.property_detail?.property_name || "",
          address: data[0]?.property_detail?.address || "",
          unitnumber: data[0]?.property_detail?.unit_number || "",
          postcode:
            data[0]?.property_detail?.postcode ||
            tempPropertyCity?.zipcode ||
            "",
          district:
            data[0]?.property_detail?.city ||
            tempPropertyCity?.district_code ||
            "",
          amphoe:
            data[0]?.contact?.district || tempPropertyAmphoe?.amphoe_code || "",
          province:
            data[0]?.contact?.province ||
            tempPropertyProvince?.province_code ||
            "",
          country:
            data[0]?.property_detail?.country ||
            tempPropertyCountry?.value ||
            ""
        };
      }
      if (data[0]?.owner_detail?.success) {
        self.ownerDetail = {
          accommodation_owned_by:
            data[0]?.owner_detail?.accommodation_owned_by || "",
          owner_name: data[0]?.owner_detail?.owner_name || [],
          alternative_name: data[0]?.owner_detail?.alternative_name || ""
        };
      }
      if (data[0]?.manager_detail?.success) {
        self.managerDetail = {
          accommodation_managed_by:
            data[0]?.manager_detail?.accommodation_managed_by || "",
          management_company_name:
            data[0]?.manager_detail?.management_company_name || "",
          alternative_name: data[0]?.manager_detail?.alternative_name || "",
          address: data[0]?.manager_detail?.address || "",
          unit_number: data[0]?.manager_detail?.unit_number || "",
          phone: data[0]?.manager_detail?.phone || "",
          telephone: data[0]?.manager_detail?.telephone || "",
          email: data[0]?.manager_detail?.email || "",
          city:
            data[0]?.manager_detail?.city ||
            tempManagerCity?.district_code ||
            "",
          amphoe:
            data[0]?.manager_detail?.district ||
            tempManagerAmphoe?.amphoe_code ||
            "",
          province:
            data[0]?.manager_detail?.province ||
            tempManagerProvince?.province_code ||
            "",
          country:
            data[0]?.manager_detail?.country || tempManagerCountry?.value || "",
          postcode:
            data[0]?.manager_detail?.postcode || tempManagerCity?.zipcode || ""
        };
      }
      if (
        data[0]?.property_detail?.success &&
        data[0]?.owner_detail?.success &&
        data[0]?.manager_detail?.success
      )
        self.cangonextstep = true;
      else self.cangonextstep = false;

      EventBus.$emit("endloading");
    },
    goupdatedata(num) {
      const self = this;
      var linkItems = null;
      if (num == 1) {
        linkItems = {
          link: `propertydetail/${self.userToken}/${self.propertyID}`,
          name: "SignupPropertyDetailScreen",
          params: { userToken: self.userToken, propertyId: self.propertyID }
        };
      } else if (num == 2) {
        linkItems = {
          link: `ownerdetail/${self.userToken}/${self.propertyID}`,
          name: "SignupOwnerDetailScreen",
          params: { userToken: self.userToken, propertyId: self.propertyID }
        };
      } else {
        linkItems = {
          link: `managerdetail/${self.userToken}/${self.propertyID}`,
          name: "SignupManagerDetailScreen",
          params: { userToken: self.userToken, propertyId: self.propertyID }
        };
      }
      self.changeStep(linkItems);
    },
    confirmData() {
      const self = this;
      var item = {
        // link: `provider/dashboard/${self.userToken}/${self.propertyID}`,
        // name: "SigninDashboardScreen",
        link: `provider/setting/generalinfo/${self.userToken}/${self.propertyID}`,
        name: "SigninGeneralInfoScreen",
        params: { userToken: self.userToken, propertyId: self.propertyID }
      };
      self.changeStep(item);
      EventBus.$emit("loadingtillend");
      self.$store.dispatch("changeDetailStep", { detailstep: self.stepdetail });
    },
    changeStep(linkItems) {
      EventBus.$emit("changePathname", linkItems);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn.btn-searchmap {
  background-color: var(--pink) !important;
  border-color: var(--pink) !important;
}

.btn.btn-searchmap:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn-cancel-searchmap:hover {
  background-color: #777 !important;
  border-color: #777 !important;
}

.btn-cancel-searchmap:hover span {
  color: #fff !important;
}
</style>
